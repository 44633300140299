export const environment = {
  api: 'http://localhost:5000', // "https://api.tavuel.com/nodejs", // "http://localhost:5000",
  p12Upload: 'http://localhost/api.php',
  cryptoAES: 'xyXTvZQTcQRt5AN',
  apiPlaces: 'http://localhost:5050',
  apiEB: 'https://api.tavuel.com/eb/tavuelBilling',
  production: false,
  // Develop
  firebase: {
    apiKey: 'AIzaSyDh0A8lxhnGOIjnBuCuBd2Q6iUS9CDuhc0',
    authDomain: 'laconfi-f7d27.firebaseapp.com',
    databaseURL: 'https://laconfi-f7d27-default-rtdb.firebaseio.com',
    projectId: 'laconfi-f7d27',
    storageBucket: 'laconfi-f7d27.appspot.com',
    messagingSenderId: '921554503995',
    appId: '1:921554503995:web:3b83daeaa89c8a17b10f1e',
    measurementId: 'G-FQ5D2SVC1Q',
    vapidKey:
      'BDYs_bbwBha8wOOmd_fCQiGqDrmSWYL9kpzm9FwS-ViXfL-fHMZAKdL6dUJj6ZnGoTsCq9D4vkycrsomIhushUw',
  },
  amplify: {
    aws_project_region: 'us-east-1',
    aws_appsync_graphqlEndpoint:
      'https://tmg3g4reaved7owjawbvb7qzuq.appsync-api.us-east-1.amazonaws.com/graphql',
    aws_appsync_region: 'us-east-1',
    aws_appsync_authenticationType: 'API_KEY',
    aws_appsync_apiKey: 'da2-zfvmtu3em5aofj5gvfnfot5k5a',
    aws_cognito_identity_pool_id:
      'us-east-1:7b38308c-d06b-45bd-a092-babcb9e4afaf',
    aws_cognito_region: 'us-east-1',
    aws_user_pools_id: 'us-east-1_QQU8Yb91w',
    aws_user_pools_web_client_id: '6irhvsf03f2ps17qbas3tv4t7',
    oauth: {},
    aws_cognito_username_attributes: ['EMAIL'],
    aws_cognito_social_providers: [],
    aws_cognito_signup_attributes: [],
    aws_cognito_mfa_configuration: 'OFF',
    aws_cognito_mfa_types: ['SMS'],
    aws_cognito_password_protection_settings: {
      passwordPolicyMinLength: 8,
      passwordPolicyCharacters: [
        'REQUIRES_LOWERCASE',
        'REQUIRES_NUMBERS',
        'REQUIRES_UPPERCASE',
      ],
    },
    aws_cognito_verification_mechanisms: ['EMAIL'],
    aws_user_files_s3_bucket: 'laconfi-storage-a0c25564142251-dev',
    aws_user_files_s3_bucket_region: 'us-east-1',
  },
  storageBucket: 'gs://laconfi-f7d27.appspot.com',
  urlBucket:
    'https://laconfi-storage-a0c25564142251-dev.s3.amazonaws.com/public/',
  userActiveId: '1a072776-30cf-4d79-9532-a3aec04fef6b',
  productStatusOffice: 'cef063fa-4610-4959-bbba-bec86b3deb5d',
  firstQuoteStatus: '08c373c4-0fbc-4f83-8735-dd804622e421',
  firstProductStatus: 'ca5001b6-2b5b-4d75-b1af-e74bfd233f45',
};
